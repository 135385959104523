:root {
  --primary: #F59100;
  --grey100: #E7E7E7;
  --grey200: #C0C0C0;
}

.material-symbols-sharp.filled {
  font-variation-settings:
    'FILL' 1,
    'wght' 600,
    'GRAD' 0,
    'opsz' 48
}

.material-symbols-sharp {
  font-variation-settings:
    'FILL' 0,
    'wght' 600,
    'GRAD' 0,
    'opsz' 48
}

body {
  padding-right: 0 !important;
}

.customMapMarker {
  background: white;
  border-radius: 20px;
  min-width: 32px;
  padding: 2px 8px;
  text-align: center;
  filter: drop-shadow(0 0 2px #C0C0C0);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.customMapMarker::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #FFF;
  position: absolute;
  bottom: -8px;
  left: calc(50% - 8px);
}

.customMapMarker.active {
  background: var(--primary);
  color: white;
}

.customMapMarker.active::after {
  border-top: 8px solid var(--primary);
}

.customMapMarker .icon {
  color: inherit;
  font-size: 16px;
}

.customMapMarker.active .icon {
  color: white;
}

.customMapMarker .favorite {
  color: var(--primary);
  font-size: 16px;
}

.customMapMarker.active .favorite {
  color: white;
}

.react-calendar {
  border: none !important;
  width: 272px !important;
}

.react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__month-view__weekdays {
  gap: 8px;
}

.react-calendar__month-view__weekdays__weekday {
  color: var(--grey200);
  font-size: 10px;
  line-height: 16px;
  max-width: 32px !important;
  text-transform: lowercase;
}
.react-calendar__month-view__weekdays__weekday::first-letter {
  text-transform: uppercase;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: unset !important;
}

.react-calendar__month-view__days {
  gap: 8px;
}

.react-calendar__tile {
  background-color: var(--grey100) !important;
  color: #000 !important;
  font-size: 14px;
  line-height: 16px;
  height: 32px !important;
  max-width: 32px !important;
  width: 32px !important;
}

.react-calendar__tile--active {
  background-color: var(--primary) !important;
  color: #FFF !important;
}

.react-calendar__tile:disabled {
  background-color: transparent !important;
  color: var(--grey100) !important;
}

.crisp-client #crisp-chatbox a[aria-label="Ouvrir le chat"] {
  bottom: 100px !important;
}